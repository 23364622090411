/* Form Container */
.form-container {
  min-height: 100vh;
  padding: 2rem;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.form-wrapper {
  width: 100%;
  max-width: 500px;
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex; /* Added */
  flex-direction: column; /* Added to stack items vertically */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

/* Product Image Section */
.product-image-section {
  margin-bottom: 2rem;
  text-align: center;
}

.product-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.product-details {
  text-align: center;
}

.product-details h3 {
  margin: 0;
  color: #1f2937;
}

.price {
  color: #2563eb;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0.5rem 0;
}

/* Form Sections */
.form-section {
  margin-bottom: 2rem;
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

h3 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

/* Form Fields */
.form-field {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #4b5563;
  font-size: 0.875rem;
}

input, textarea, select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  font-size: 1rem;
}

textarea {
  resize: vertical;
}

input:focus, textarea:focus, select:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 2px #93c5fd;
}

/* Payment Options */
.payment-options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.payment-option {
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s;
}

.payment-option:hover {
  border-color: #2563eb;
}

.payment-option.selected {
  border-color: #2563eb;
  background-color: #eff6ff;
}

.payment-content h4 {
  margin: 0;
  font-size: 0.875rem;
  color: #1f2937;
}

.payment-content p {
  margin: 0.25rem 0 0;
  font-size: 0.75rem;
  color: #6b7280;
}

/* Submit Button */
.submit-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: -2.5rem;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background-color: #1d4ed8;
}

.submit-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #93c5fd;
}

.blinking {
  animation: blink-animation 0.6s steps(1, end) infinite;
}

@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
