.video-container {
    position: relative;
    width: 100%;
    max-width: 600px; /* Adjust the max-width as needed */
  }
  
  video {
    display: block;
    width: 100%;
    cursor: pointer;
  }
  