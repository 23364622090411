/* FAQMobileView.css */
.faq-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .faq-title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .faq-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .faq-item {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    background-color: #f9f9f9;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    background-color: whitesmoke;
    color: black;
  }
  
  .faq-icon {
    font-size: 20px;
  }
  
  .faq-answer {
    padding: 15px;
    background-color: #fff;
    color: #333;
    font-size: 16px;
    line-height: 1.5;
  }
  