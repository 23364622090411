.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40%;
    margin: 0;
    text-align: center;
    width: 100%;
    padding: 20px; /* Optional: adds padding to the container */
  }
  
  .title {
    font-size: 2rem;
    margin: 0;
    margin-bottom: 20px;
  }
  
  .description {
    font-size: 1.2rem;
    margin-bottom: 20px;
    width: 80%;
  }
  
  .button {
    padding: 14px 28px;
    font-size: 1.2rem; /* Slightly larger text for emphasis */
    background-color: #FF69B4; /* Brighter pink base */
    color: #FFFFFF; /* White text for contrast */
    border: none;
    border-radius: 30px; /* Extra rounded for a pill shape */
    cursor: pointer;
    margin-bottom: 10%;
    box-shadow: 0 6px 10px rgba(255, 105, 180, 0.3); /* Brighter pink shadow */
    font-family: 'Arial', sans-serif; /* Clean and professional font */
    font-weight: bold;
    text-transform: uppercase; /* Makes the text stand out */
    letter-spacing: 1px; /* Slight spacing for elegance */
    transition: all 0.3s ease;
}

.button:hover {
    background-color: red; /* Light pink for hover */
    box-shadow: 0 8px 12px rgba(255, 182, 193, 0.4); /* Softer pink shadow */
    transform: scale(1.05); /* Slight zoom for hover interaction */
}

.button:active {
    background-color: #FF1493; /* Deep pink when clicked */
    box-shadow: 0 4px 6px rgba(255, 20, 147, 0.3); /* Lowered shadow */
    transform: scale(1); /* Reset scale when clicked */
}

