body, html {
    margin: 0;
    padding: 0;
  }

  h1 {
    font-size: 1.4rem; /* 32px for titles */
  }
  
  .image-container {
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    justify-content: center; /* Center content vertically */
    text-align: center;
  }
  
  .full-width-image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #25d366; /* WhatsApp green */
    color: #fff; /* White text */
    border: none;
    border-radius: 25px; /* Rounded corners */
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    margin-bottom: 20px;
  }
  
  /* Add a shiny effect */
  button::before {
    content: "";
    position: absolute;
    top: 0;
    left: -75px;
    width: 50px;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    transform: skewX(-30deg);
    transition: all 0.7s ease-in-out;
  }
  
  button:hover::before {
    left: 125%;
  }
  
  button:hover {
    background-color: #128C7E; /* Darker WhatsApp green on hover */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
    transform: scale(1.05); /* Slight zoom effect */
  }
  

  img {
    pointer-events: none;
    user-select: none;
  }