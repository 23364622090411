body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 10;
  padding: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

p {
  line-height: 1.8;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #16212d;
}

.header .links {
  margin: 0 10px;
  color: #fff;
  text-decoration: none;
}

.showcase {
  background: url('https://images.pexels.com/photos/46148/aircraft-jet-landing-cloud-46148.jpeg?auto=compress&cs=tinysrgb&h=650&w=940')
    no-repeat center/cover;
  height: 650px;
}

.showcase .showcase-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 650px;
  text-align: center;
  color: #fff;
}

.showcase .showcase-overlay h1 {
  font-size: 36px;
  padding-bottom: 20px;
}

.destinations {
  padding: 50px;
  text-align: center;
}

.destinations h3 {
  font-size: 30px;
  padding: 10px;
  text-align: center;
}

.destinations .grid div {
  background-color: #f1f1f1;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 10px;
}

.destinations .grid div img {
  border-radius: 10px;
}

.footer {
  background-color: #333;
  padding: 40px;
}

.footer ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer ul li {
  margin: 0 20px;
}

.footer ul li button {
  background-color: transparent;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 5px;
  margin: 10px 0;
  cursor: pointer;
  font-family: inherit;
  transition: background-color 0.4s ease-in-out;
}

.footer ul li button:hover {
  background-color: #2c7f86;
}

/* Login and SignUp */
.login .form-control {
  display: flex;
  flex-direction: column;
}

.login .form-control input {
  margin: 5px 0;
  padding: 12px;
  border: none;
  border-radius: 10px;
  width: 80vw;
  font-family: inherit;
  font-weight: 700;
  letter-spacing: 2px;
}

.login .form-control button {
  background-color: #2c7f86;
  padding: 8px;
  border: 2px solid #2c7f86;
  color: #fff;
  font-family: inherit;
  border-radius: 10px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 18px;
  margin-top: 5px;
  cursor: pointer;
  transition: background-color 0.4s;
}

.login .form-control button:hover {
  background-color: transparent;
}

@media (min-width: 768px) {
  .showcase {
    background-position: top;
  }

  .showcase .showcase-overlay h1 {
    font-size: 64px;
  }

  .showcase .showcase-overlay p {
    font-size: 18px;
  }

  .destinations .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .destinations .grid div img {
    height: 150px;
    width: 300px;
  }

  .footer ul {
    flex-direction: row;
    justify-content: space-between;
  }

  .login .form-control input {
    width: 50vw;
  }
}
